



















































































































































































































































































































import { Board, Target, TargetState, TestChannelStateCode } from '@/includes/types/Board.types'
import { BoardService } from '@/includes/services/BoardService'
import { ChannelService } from '@/includes/services/ChannelService'
import ChannelCard from '@/components/ChannelCard.vue'
import TestStatusInfo from '@/components/TestStatusInfo.vue'
import TestStateMixin from '@/mixins/TestStateMixin'
import AddBotGuide from '@/components/TestStatusHelpInfo/Channel/AddBotGuide.vue'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { errorNotification } from '@/includes/services/NotificationService'
import SaveConfigPanel from '@/components/SaveConfigPanel.vue'
import LogChannelGuide from '@/components/ChannelsGuide/LogChannelGuide.vue'
import InactiveChannelGuide from '@/components/ChannelsGuide/InactiveChannelGuide.vue'
import { GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'
import PageTitle from '@/components/PageTitle.vue'

import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import FieldTitle from 'piramis-base-components/src/components/Pi/components/FieldTitle.vue'

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters([ 'getBoardById' ])
  },
  components: {
    PageTitle,
    InactiveChannelGuide,
    LogChannelGuide,
    SaveConfigPanel,
    CenteredColumnLayout,
    AddBotGuide,
    ChannelCard,
    TestStatusInfo,
    EmptyData,
    HelpMessage,
    FieldTitle
  },
  data() {
    return {
      TargetState
    }
  }
})
export default class BoardChannels extends Mixins<TestStateMixin>(TestStateMixin) {
  getBoardById!: GetBoardByIdType

  allChannels: Array<Target> = []

  isMounted = false

  activeChannelId: number | null = null

  isPopupActive = false

  isLoading = false

  get inactiveChannels(): Array<Target> {
    return this.allChannels
      .filter((channel) => channel.state === TargetState.None)
  }

  get boardChannels(): Array<Target> {
    return this.allChannels
      .filter((channel) => channel.state === TargetState.Board)
      .sort((a, b) => b.members_count - a.members_count)
  }

  get logChannel(): Array<Target> {
    return this.allChannels
      .filter((channel) => channel.state === TargetState.Log)
  }

  handleOnChannelStateButtonClick(channelId: number): void {
    this.activeChannelId = channelId
    this.isPopupActive = true
  }

  handleUpdateChannelTestState(channelInfo: { state: TestChannelStateCode, channelId: number, boardId: string }): void {
    this.allChannels = this.allChannels.map(value => {
      if (value.id === channelInfo.channelId) {
        return {
          ...value,
          test_state: channelInfo.state
        }
      }
      return value
    })
  }

  goToByRouteName(routeName: string): void {
    this.$router.push({
      name: routeName,
      params: {
        id: this.getBoardById(this.$route.params.id)!.board.split('.')[0]
      }
    })
  }

  updateChannelState(new_state: TargetState, channelId: number): void {
    this.isPopupActive = false
    this.isLoading = true
    ChannelService.updateChannelState('tg', {
      board_key: this.getBoardById(this.$route.params.id)!.board,
      channel_id: channelId,
      new_state: new_state
    })
      .then(() => this.getBoardChannels())
      .catch(errorNotification)
      .finally(() => this.isLoading = false)
  }

  async getBoardChannels(): Promise<void> {
    try {
      this.isLoading = true
      const data: { board: Board, channels: Array<Target> } = await BoardService.getBoardChannels('tg', { board_key: this.getBoardById(this.$route.params.id)!.board })
      this.allChannels = data.channels

      const boardsCopy: Array<Board> = cloneDeep(this.$store.state.boardsState.boards)
      const index = boardsCopy.findIndex(b => b.board === data.board.board)
      const board = boardsCopy.find(b => b.board === data.board.board)

      if (board) {
        board.targets = data.channels

        boardsCopy.splice(index, 1)
        boardsCopy.push(board)

        this.$store.commit('set_boards_to_store', boardsCopy)
      }

    } catch (error: any) {
      errorNotification(error)
    } finally {
      this.isLoading = false
      this.isMounted = true
    }
  }

  async mounted(): Promise<void> {
    await this.getBoardChannels()
  }
}
