





































import ZoomableImage from 'piramis-base-components/src/components/ZoomableImage/ZoomableImage.vue'

import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  components: {
    ZoomableImage
  }
})
export default class AddBotGuide extends Vue {
}
